    form .error{
        color: red;
    }
    
    .faded {
        opacity: 0.5;
    }

    .collection list > a.collection-item:hover {
    	//background-color: #eed5ee !important;
    }