.pagination li span{
    color: #444;
    display: inline-block;
    font-size: 1.2rem;
    padding: 0 10px;
    line-height: 30px;
}

.pagination li a:hover{
    background-color: rgba(0,0,0,0.2);
}