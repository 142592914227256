.dropdown-content {
    top: 64px !important;
    right: 0px !important;
    width: 320px !important;
    left: initial !important;
}

.dropdown-content > a {
    height: initial;
}

ul#notificationsList li {
    border-bottom: 1px inset grey;
}
ul#notificationsList li.clicked{
    background-color: lightgrey;
}

#notificationsList > .collection-item > a, #notificationsList > .collection-item > span {
    padding: initial;
}

#notificationsList > .collection-item > a:hover {

}

p.notification-date {
    font-size: 10px;
}

ul#notificationsList a.secondary-content {
    height: auto;
}

ul#notificationsList > .collection-item > a {
    color: initial;
}

ul#notificationsList > .collection-item > a:hover {
    background-color: initial;
}

.nav-wrapper li:hover {
    background-color: rgba(255,255,255,0.2);
}