.ads {

}

.ads img {
    vertical-align: bottom;
}

.ads.ads-banner.left, .ads.ads-banner.right {
    position:absolute;
    top: 64px;
    height: 100%; /* height of material navbar: 64 */
    width:15vw;
}

.ads.ads-banner.left img, .ads.ads-banner.right img{
    width:100%;
    height: 50%;
}

.ads.ads-banner.top, .ads.ads-banner.bottom {
    position:fixed;
    height: 64px;
    width:100%;
    left:0px;
}

.ads.bottom {
    bottom: 0px;
}
.ads.top{
    top: 0px;
}

.ads.left {
    left:0px;
}

.ads.right {
    right:0px;
}